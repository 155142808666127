.Toastify__toast--error {
  border: 1px solid #eb5757;
  background: #fae1e2 !important;
}
.Toastify__toast--error::after {
  //   content : url('../assets/images/svg/closeToast.svg');
  position: absolute;
  color: #333333;
  font-size: 20px;
  font-weight: 700;
  padding: 14px !important;
}
.Toastify__toast--error::before {
  //   content: url("../assets/images/svg/errorToast.svg");
  position: relative;
  z-index: 100000;
}
.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  background: #f0f9fa !important;
}
.Toastify__toast--success::before {
  //   content: url("../assets/images/svg/successToast.svg");
  position: relative;
  z-index: 100000;
}
.Toastify__toast--success::after {
  //   content : url('../assets/images/svg/closeToast.svg');
  position: absolute;
  color: #333333;
  font-size: 20px;
  font-weight: 700;
  //   left:265px;
  padding: 14px !important;
}
.Toastify__toast--warning {
  border: 1px solid #e78326 !important;
  background: #fadfc5 !important;
}
.Toastify__toast--warning::before {
  //   content: url("../assets/images/svg/warnToast.svg");
  position: relative;
  z-index: 100000;
}
.Toastify__toast--warning::after {
  //   content : url('../assets/images/svg/closeToast.svg');
  position: absolute;
  color: #e78326;
  font-size: 20px;
  font-weight: 700;
  padding: 14px !important;
}
.Toastify__toast-body {
  color: #444c63;
  font-size: 18px;
  line-height: 20px;
  padding: 25px;
  width: 100%;
  font-weight: 400;
}
.Toastify__toast > button > svg {
  display: none;
}
.Toastify__toast-container--top-center {
  top: 45% !important;
}
.Toastify__toast-container {
  width: auto !important;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--top-center {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}
