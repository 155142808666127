@import "./../Variables/Variables.scss";

.dataprivacyimg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 25rem;
  padding-left: 15.3125% !important;
    padding-right: 15.3125% !important; 
}

.dataprivacyimgpara {
  font-weight: 700 !important;
  font-size: 4rem !important;
  color: $whiteColor;
  line-height: 3.75rem !important;
}

.privacyHeading{
    font-size: 2.25rem;
    font-weight: 700 !important;
    color: $redColor;
    text-align: center;
    line-height: normal !important;
}

.privacyTextHeading{
    font-size: 1.125rem;
    font-weight: 700 !important;
    color: $redColor;
    margin-bottom: 1.25rem !important;
    line-height: 1.5rem !important;
}

.privacyText{
    font-size: 1.125rem;
    font-weight: 600 !important;
    line-height: 1.5rem !important;
}

@media screen and (max-width: 800px) {
    .dataprivacyimgpara{
        font-size: 3rem !important;
    }
}
@media (max-width: 1400px) {
    .dataprivacyimg{
        padding-left: 10% !important;
    padding-right: 10% !important;
    transition: width 2s,
    }
}
@media (max-width: 1250px){
    .dataprivacyimg{
        padding-left: 5% !important;
    padding-right: 5% !important;
    transition: width 2s,
    }
  }


.dataprivacycontentbox .css-1c5ij41{
    margin: 34px !important;
}

.removeAccordionBorder{
    // border: none !important;
    // box-shadow: 1px 1px 0px 0px !important;
    // box-shadow: 0px 19px 38px 0px rgba(0,0,0,0.3), 0px -19px 38px 0px;
    box-shadow: rgba(0, 0, 0, 0) 0px 2px 4px 0px inset !important;
    padding: 10px 0px !important;
}

.removeaccordion{
    box-shadow: none !important;
    padding: 10px 0px !important;
}

.greyline{
    border: 1px solid #C7C7C7 !important;
}
