@import "./../Variables/Variables.scss";

.contactusbannerimg {
  background: url(../../Images/contactusbanner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  // min-height: 25rem;
  min-height: 40vh;
}

.contactusbannerimgKorean {
  background: url(../../Images/contactusbannerKorean.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  // min-height: 25rem;
  min-height: 55vh;
}

.contactusbannerimgHebrewbanner {
  background: url(../../Images/contactusbannerHebrew.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  // min-height: 25rem;
  min-height: 55vh;
}

.reCaptcha {
  zoom: 1.25 !important;
}
#rc-imageselect {
  zoom: 1.25 !important;
}
.g-recaptcha-bubble-arrow {
  zoom: 1.25 !important;
}

div:has(> .g-recaptcha-bubble-arrow) {
  zoom: 1.25 !important;
  left: 5px !important;
}

.contactusbannerimgpara {
  font-weight: 700 !important;
  font-size: 4rem !important;
  color: $whiteColor;
  line-height: normal !important;
}
.contactusbannerimgparaKorean {
  font-weight: 700 !important;
  font-size: 3.5rem !important;
  color: $whiteColor;
  line-height: normal !important;
}
.contactusbannerimgparaKorean2 {
  font-weight: 700 !important;
  font-size: 2.5rem !important;
  color: $whiteColor;
  line-height: normal !important;
}
.contactusbannerimgparaHebrew {
  font-weight: 700 !important;
  font-size: 6rem !important;
  color: $whiteColor;
  line-height: normal !important;
}
.contactusbannerimgpara2 {
  font-weight: 600 !important;
  font-size: 2.5rem !important;
  color: $whiteColor;
  line-height: normal !important;
}

.hebrew ::-webkit-input-placeholder {
  text-align: right !important;
}

.contactusformheading {
  color: $redColor;
  font-weight: 700 !important;
  font-size: 2.25rem !important;
  line-height: normal !important;
}
.contactusformheadingHebrew {
  color: $redColor;
  font-weight: 700 !important;
  font-size: 2.75rem !important;
  line-height: normal !important;
}
.contactusformheadingCadmin {
  color: $redColor;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: normal !important;
}

.bordr10px {
  border-radius: 10px;
}

.sendmsgBtn {
  color: $whiteColor !important;
  background-color: $redColor !important;
  width: 48.027%;
  height: 50px;
  border-radius: 5px !important;
  text-transform: capitalize !important;
  font-size: 1.125rem !important;
  transition: 0.4s !important;
  font-weight: 400 !important;
}
.sendResendOTPBtn {
  color: $whiteColor !important;
  background-color: #ed322585 !important;
  width: 48.027%;
  height: 50px;
  border-radius: 5px !important;
  text-transform: capitalize !important;
  font-size: 1.125rem !important;
  transition: 0.4s !important;
  font-weight: 400 !important;
}
.sendmsgBtnLogin {
  color: $whiteColor !important;
  background-color: $redColor !important;
  width: 100%;
  height: 50px;
  border-radius: 5px !important;
  text-transform: capitalize !important;
  font-size: 1.125rem !important;
  transition: 0.4s !important;
  font-weight: 400 !important;
}

.locationBtn {
  color: $whiteColor !important;
  background-color: #ed3125 !important;
  min-width: 10rem;
  height: 60px;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  font-size: 1.875rem !important;
  transition: 0.4s !important;
  font-weight: 600 !important;
  line-height: normal !important;
  display: inline-flex;
}

.Fgap27 {
  gap: 2.7%;
}

.getintouchform .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 2em;
  color: #636363 !important;
}

.loginContainer {
  position: fixed;
  z-index: 1111;
  top: 0;
  width: 100%;
  height: 125vh !important;
  background: rgb(0 0 0 / 70%);
}
.loginContainerConfirmOTPScreen {
  position: fixed;
  z-index: 1112;
  top: 0;
  width: 100%;
  height: 125vh !important;
  background: rgb(0 0 0 / 70%);
}

.loginForm {
  // width: 400px !important;
  background-color: white;
}

.otpForm {
  max-width: 420px !important;
  background-color: white;
}
.signUpForm {
  width: 50% !important;
  background-color: white;
}
.loginTextSubHeading {
  font-size: 1rem !important;
  color: #505050 !important;
}

.getintouchform {
  min-height: 450px;
}

.bordr5px {
  border-radius: 5px;
}

.iconssubhead {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.iconscontent {
  font-size: 1.125rem !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.textfieldstyle {
  height: 50px !important;
  outline: none !important;
  margin-bottom: 20px !important;
  width: 48.027% !important;
}
.textFieldStyleSignUp {
  height: 50px !important;
  outline: none !important;
  margin-bottom: 20px !important;
  width: 48.027% !important;
}
.LoginInputs {
  height: 50px !important;
  outline: none !important;
  margin-bottom: 20px !important;
  // width: 48.027% !important;
}
.css-1tq8nf4-MuiFormControl-root-MuiTextField-root input {
  outline: none !important;
  background: #f5f5f5 !important;
}
.hoverUnderline:hover{
  text-decoration: underline !important;
  color: rgba(42, 42, 176, 0.75) !important;
}

.textfieldstylefull {
  height: 100px !important;
  outline: none !important;
  margin-bottom: 20px !important;
  width: 100% !important;
}

.iconsboxstyle {
  height: 7.5rem;
  margin-bottom: 1.875rem;
}

.iconimgstyle {
  width: 74px;
  height: 74px;
  background-color: #395fd2;
  margin: auto 28px;
}

.paddingtop125 {
  padding-top: 1.75rem;
}

.contactuslocationheading {
  color: #395fd2;
  font-size: 2.25rem !important;
  font-weight: 700 !important;
  text-align: center;
  margin-top: 7.063rem !important;
  line-height: normal !important;
}

.locationbox {
  margin-top: 1.5rem;
}

.locationimg {
  height: 250px;
  width: 348px;
  border-radius: 10px;
}

.getintouchinputfield .css-1o6z5ng {
  color: #636363 !important;
}

// .css-yem0l2-MuiPopper-root-MuiTooltip-popper{
//   transform: translate(-220px, 404px) !important;
// }

.locationimgtext {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  text-align: center;
  margin-top: 1.5rem !important;
  color: #000;
  line-height: normal !important;
}

.outerlocationbox {
  margin-top: 3.688rem;
}

.callandemailbox {
  width: 40.05%;
}

.Contactustermsofusage {
  font-size: 0.75rem !important;
  font-weight: 300 !important;
}
.ContactustermsofusageKorea {
  font-size: 1rem !important;
  font-weight: 300 !important;
}
.ContactustermsofusageHebrew {
  font-size: 1.125rem !important;
  font-weight: 300 !important;
}

@media screen and (max-width: 650px) {
  // .contactusbannerimg{
  //   min-height: 18rem !important;
  // }
  .contactusbannerimgpara {
    font-size: 2.5rem !important;
  }
  .contactusbannerimgparaKorean {
    font-size: 2.25rem !important;
  }
  .contactusbannerimgparaKorean2 {
    font-size: 1.75rem !important;
  }
  .contactusbannerimgparaHebrew {
    font-size: 3rem !important;
  }
  .contactusbannerimgpara2 {
    font-size: 2rem !important;
  }
  .contactuslocationheading {
    margin-top: 3rem !important;
  }

  div:has(> iframe) {
    margin: 0px !important;
  }
  .reCaptcha iframe {
    zoom: 1.25 !important;
  }

  .reCaptcha {
    zoom: 1.25 !important;
  }
  iframe {
    width: 500px !important;
    height: 760px !important;
    // height: 900px !important;
    // width: 650px !important;
  }
  .reCaptcha iframe {
    zoom: 1 !important;
    width: 304px !important;
    height: 77px !important;
  }

  #rc-imageselect {
    zoom: 0.8 !important;
  }
  .g-recaptcha-bubble-arrow {
    zoom: 0.8 !important;
  }
  div:has(> .g-recaptcha-bubble-arrow) {
    zoom: 0.8 !important;
    left: 5px !important;
  }
}

@media screen and (max-width: 800px) {
  .signUpForm {
    width: 80% !important;
  }
  .textFieldStyleSignUp {
    width: 100% !important;
  }
}
@media screen and (max-width: 600px) {
  .signUpForm {
    width: 100% !important;
  }
}
@media screen and (max-width: 800px) {
  .locationimgtext {
    margin-top: 0.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .iconimgstyle {
    margin: auto 12px !important;
  }
  .loginContainerConfirmOTPScreen {
    position: fixed;
    z-index: 1112;
    top: 0;
    width: 100%;
    height: 125vh !important;
    background: rgb(0 0 0 / 70%);
    overflow: scroll;
  }
  .loginContainerConfirmOTPScreen::-webkit-scrollbar{
      display: none !important;
  }
}

@media screen and (max-width: 750px) {
  .MR15 {
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 700px) {
  .getintouchContainer {
    flex-flow: column;
  }
  .getintouchform {
    width: 100% !important;
  }
  .callandemailbox {
    width: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  .locationimg {
    width: 100% !important;
  }
}
@media screen and (max-width: 1100px) {
  .textfieldstyle {
    width: 100% !important;
  }
}

@media screen and (min-width: 700px) and (max-width: 1100px) {
  .iconsboxstyle {
    height: 9.5rem;
  }
}

@media screen and (min-width: 700px) and (max-width: 1100px) {
  .getintouchform {
    width: 90% !important;
  }
  .callandemailbox {
    width: 55% !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
  .iconimgstyle {
    margin: auto 12px !important;
  }
}

@media screen and (min-width: 400px) and (max-width: 700px) {
  .callandemailbox {
    width: 90% !important;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1132px) {
  .locationimg {
    width: 220px;
    height: 180px;
  }
}
@media screen and (min-width: 1132px) and (max-width: 1218px) {
  .locationimg {
    width: 240px;
    height: 200px;
  }
}
@media screen and (min-width: 1218px) and (max-width: 1304px) {
  .locationimg {
    width: 260px;
  }
}
@media screen and (min-width: 1304px) and (max-width: 1390px) {
  .locationimg {
    width: 280px;
  }
}
@media screen and (min-width: 1390px) and (max-width: 1480px) {
  .locationimg {
    width: 300px;
  }
}
@media screen and (min-width: 1480px) and (max-width: 1520px) {
  .locationimg {
    width: 320px;
  }
}
@media screen and (min-width: 1520px) and (max-width: 1598px) {
  .locationimg {
    width: 330px;
  }
}
@media screen and (min-width: 1640px) and (max-width: 1750px) {
  .locationimg {
    width: 380px;
  }
}
@media screen and (min-width: 1750px) and (max-width: 1920px) {
  .locationimg {
    width: 420px;
  }
}
