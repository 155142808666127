@import "./../Variables/Variables.scss";

.announcementimg {
  background: url(../../Images/Announcement.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 40vh;
}

.announcementimgpara {
  font-weight: 700 !important;
  font-size: 4rem !important;
  color: $whiteColor;
}

.announcementouterbox {
  border-radius: 10px;
}

@media screen and (max-width: 800px) {
  .announcementimgpara{
    font-size: 3rem !important;
  }
}
@media screen and (max-width: 900px) {
  .announcementimg {
    min-height: 18rem;
  }
}