// colors
$blackColor: rgb(0, 0, 0);
$whiteColor: rgb(255, 255, 255);
$redColor: #ED3125;
$blueColor: #395fd2;
$greyColor: #D9D9D9;
$skyBlueColor: #F3F9FE;
$offWhite:#FCFCFC;
$whiteSmoke: #F5F5F5;
$lightRed: #EEEEEE;
$lightestRed: #F8F8F8;
$gray78 :  #C7C7C7;
$darkGreyCyan : #616564;